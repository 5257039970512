<template>
  <div class="home-main-wrapper">
    <topNavigation />
    <section>
      <div class="section-1 text-center first_1">
        <div class="mt-6">
          <h1>RelifeMS offers Docsmart Healthcare System.</h1>
          <h5 class="mt-6">A Secure and Confidential health care portal on your fingertips.</h5>
          <a class="btn demo-btn" href="https://www.youtube.com/@docsmart3294/videos" target="_blank">View Demo</a>
        </div>
        <div>
          <img src="/img/19834.jpg" alt="home" />
        </div>
      </div>
    </section>
    <!-- ................. centers ......................... -->
    <section>
      <div class="section-2 my-10">
        <div class="con">
          <div class="home-centers">
            <div>
              <doctor></doctor>
              <h4>Doctor</h4>
            </div>
            <div @click="serviceModal = true">
              <ambulance></ambulance>
              <h4>Ambulance</h4>
            </div>
            <div @click="serviceModal = true">
              <hospital></hospital>
              <h4>Hospital</h4>
            </div>
            <div @click="serviceModal = true">
              <medicalRecord></medicalRecord>
              <h4>Diagnostic</h4>
            </div>
            <div @click="serviceModal = true">
              <prescription />
              <h4>Pharmacy</h4>
            </div>
            <div @click="serviceModal = true">
              <blood></blood>
              <h4>Blood Bank</h4>
            </div>
            <div @click="serviceModal = true">
              <customerService></customerService>
              <h4>Sales & Services</h4>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- ................. end centers ......................... -->
    <!-- about us -->
    <section>
      <div class="section-3 my-10 home-about">
        <div class="w-50v abt-back">
          <!-- <b-img-lazy src="/images/mac.png" alt /> -->
          <img src="/img/mac.png" class="img-fluid" alt="Mac" />
        </div>
        <div class="w-50v con-3">
          <div>
            <h1 class="h1">About Us</h1>
            <p
              class="p-info"
            >Many Software’s exist in the market. But they all work in isolation and cater to only a part of the health care eco-system.</p>
            <p
              class="p-info"
            >ReLife-DocSmart is a novel health care software which addresses this lacunae and helps in linking all health care services under a Common cloud based Health care Ecosystem (HES).</p>
            <p
              class="p-info"
            >Various Modules for health care Providers are created which talk among themselves in real time without any external interference, maintaining a secure and confidential environment.</p>
            <p class="p-info">
              Unlike many existing software’s the Search Module for Providers is offered free so that genuine information is shared with the patients.
              The App allows for genuine Rating and Reviews along with features towards online Chat and Grievance redressal among others.
              All modules for the Providers are comprehensively made to include Appointments, Staff, Stock and Accounting features among others.
            </p>
          </div>
        </div>
      </div>
    </section>
    <!-- end about us -->
    <!-- services -->
    <section>
      <div class="section-4 home-services text-center">
        <div class="ww-100">
          <h1 class="h1">Services</h1>
          <h3>ReLife-DocSmart Offers a gamut of services. A platform for all stake holders to interact among themselves.</h3>
          <div class="con">
            <div class="service-left ww-100">
              <span
                hidden
                @click="serviceTab = 'service'"
                :class="{ active: serviceTab == 'service' }"
              ></span>
              <div
                class="service-list text-center"
                @click="serviceTab = 'doctor'"
                :class="{ active: serviceTab == 'doctor' }"
              >
                <div>
                  <doctor></doctor>
                </div>
                <p>Doctors</p>
              </div>
              <div
                class="service-list text-center"
                @click="serviceTab = 'patient'"
                :class="{ active: serviceTab == 'patient' }"
              >
                <div>
                  <patientSVG />
                </div>
                <p>Patient</p>
              </div>
              <div
                class="service-list text-center"
                @click="serviceTab = 'ambulance'"
                :class="{ active: serviceTab == 'ambulance' }"
              >
                <div>
                  <ambulance></ambulance>
                </div>
                <p>Ambulance</p>
              </div>
              <div
                class="service-list text-center"
                @click="serviceTab = 'blood'"
                :class="{ active: serviceTab == 'blood' }"
              >
                <div>
                  <blood></blood>
                </div>
                <p>Blood Bank</p>
              </div>
              <div
                class="service-list text-center"
                @click="serviceTab = 'hospital'"
                :class="{ active: serviceTab == 'hospital' }"
              >
                <div>
                  <hospital></hospital>
                </div>
                <p>Hospital</p>
              </div>
              <div
                class="service-list text-center"
                @click="serviceTab = 'prescription'"
                :class="{ active: serviceTab == 'prescription' }"
              >
                <div>
                  <prescription></prescription>
                </div>
                <p>Pharmacy</p>
              </div>
              <div
                class="service-list text-center"
                @click="serviceTab = 'medicalRecord'"
                :class="{ active: serviceTab == 'medicalRecord' }"
              >
                <div>
                  <medicalRecord></medicalRecord>
                </div>
                <p>Diagnostic</p>
              </div>
              <div
                class="service-list text-center"
                @click="serviceTab = 'chat'"
                :class="{ active: serviceTab == 'chat' }"
              >
                <div>
                  <chat></chat>
                </div>
                <p>Chat</p>
              </div>
              <div
                class="service-list text-center"
                @click="serviceTab = 'seller'"
                :class="{ active: serviceTab == 'seller' }"
              >
                <div>
                  <seller></seller>
                </div>
                <p>Vendor</p>
              </div>
              <div
                class="service-list text-center"
                @click="serviceTab = 'stockist'"
                :class="{ active: serviceTab == 'stockist' }"
              >
                <div>
                  <stockist></stockist>
                </div>
                <p>Pharma / Stockist</p>
              </div>
              <div
                class="service-list text-center"
                @click="serviceTab = 'insurance'"
                :class="{ active: serviceTab == 'insurance' }"
              >
                <div>
                  <insurance></insurance>
                </div>
                <p>Insurance</p>
              </div>
              <div
                class="service-list text-center"
                @click="serviceTab = 'court'"
                :class="{ active: serviceTab == 'court' }"
              >
                <div>
                  <court></court>
                </div>
                <p>Government</p>
              </div>
            </div>
            <div class="service-right-main ww-100" id="docsmartServices">
              <div class="service-right">
                <div class="service-info">
                  <!-- service -->
                  <div v-if="serviceTab == 'service'">
                    <div class="top-div">
                      <service></service>
                      <h4>About Services</h4>
                    </div>
                    <p class="p-info text-left">
                      Lorem Ipsum is simply dummy five centuries, but also the
                      leap into electronic typesetting, remaining essentially
                      unchanged. It was popularised in the 1960s with the
                      release of Letraset sheets containing Lorem Ipsum
                      passages, and more recently with desktop publishing
                      software like Aldus PageMaker including versions of Lorem
                      Ipsum.
                    </p>
                  </div>
                  <!-- end service -->
                  <!-- doctor -->
                  <div v-if="serviceTab == 'doctor'">
                    <div class="top-div">
                      <doctor></doctor>
                      <h4>Doctor</h4>
                    </div>
                    <p class="p-info text-left">
                      All the needs of a doctor in the form of Visibility, Appointment Booking, Out Patient Module, In Patient online Hospital records Access, Online Consultation, Telemedicine, Conferences, Online Meetings, Webinars, News-Blogs-Forums and Emergency Codes form part of this software.
                      The doctor can access health records in real time.
                    </p>
                  </div>
                  <!-- end doctor -->
                  <!-- patient -->
                  <div v-if="serviceTab == 'patient'">
                    <div class="top-div">
                      <patientSVG />
                      <h4>patient</h4>
                    </div>
                    <p class="p-info text-left">
                      The Patients have access in real time to their Medical Records, Appointments, Reports, Certificates, Online Consultation, Second Opinion and Grievance Redressal.
                      The App offers ease of Searching for Doctors, Ambulance, Hospitals, Pharmacy, Diagnostic centres, Blood Banks, Vendors and Services.
                      The App informs Cost towards services and Disease information in real time. The Privacy and security of data is maintained at all times.
                    </p>
                  </div>
                  <!-- end patient -->
                  <!-- ambulance -->
                  <div v-if="serviceTab == 'ambulance'">
                    <div class="top-div">
                      <ambulance></ambulance>
                      <h4>ambulance</h4>
                    </div>
                    <p class="p-info text-left">
                      Emergency as well as routine Ambulance can be booked on line with ease.
                      The category of Ambulance, their availability, Staff allocation, and real time Location of ambulance can be seen by the Ambulance centre.
                      The Driver mobile app completes the trip with costing.
                      The App can help during a Disaster with timely allocation of Ambulance and Hospital Beds.
                    </p>
                  </div>
                  <!-- end ambulance -->
                  <!-- blood -->
                  <div v-if="serviceTab == 'blood'">
                    <div class="top-div">
                      <blood></blood>
                      <h4>bloodbank</h4>
                    </div>
                    <p class="p-info text-left">
                      Online Emergency booking and Reservation of blood is possible by the App.
                      Availability of blood, Issuing of Blood, Blood Donation , Online Camp Organizer, Staff allocation, Testing of blood and Technician module is provided.
                      Delivery of blood to hospitals can be followed in real time. This prevents wastage of Blood.
                    </p>
                  </div>
                  <!-- end blood -->
                  <!-- hospital -->
                  <div v-if="serviceTab == 'hospital'">
                    <div class="top-div">
                      <hospital></hospital>
                      <h4>hospital</h4>
                    </div>
                    <p
                      class="p-info text-left"
                    >Hospitals get Online Reach to Patients, Doctors and Insurance. Online Searchable variants are incorporated such as: Services offered, Panel of Doctors, Health and Disease packages and Emergency Facility available. Hospitals have been given Polyclinic and IPD software which includes Staff, Stock and accounting modules. The Software has presence of various Emergency Codes and Protocols and Crowd Management System in place.</p>
                  </div>
                  <!-- end hospital-->
                  <!-- prescription -->
                  <div v-if="serviceTab == 'prescription'">
                    <div class="top-div">
                      <prescription></prescription>
                      <h4>Prescription</h4>
                    </div>
                    <p
                      class="p-info text-left"
                    >ReLife-DocSmart offers Online reach to Patients and E prescription connect. It incorporates a Pharmacy software having both generic and brand names of medicines. It has Provision for Online Purchase from stockists and Auto update of medicines data. It has provision for Home delivery of medicine.</p>
                  </div>
                  <!-- end prescription -->
                  <!-- medicalRecord -->
                  <div v-if="serviceTab == 'medicalRecord'">
                    <div class="top-div">
                      <medicalRecord></medicalRecord>
                      <h4>medicalRecord</h4>
                    </div>
                    <p
                      class="p-info text-left"
                    >Diagnostic centres are seen in search and E booking of Tests and Health packages can be done directly by the patients or Doctors. Online reports are shared with Patients and Doctors. Provision for Critical value reporting. Technician module for Collection, Testing and Home Visit. Provision for Insurance and company health check for patients.</p>
                  </div>
                  <!-- end medicalRecord -->
                  <!-- chat -->
                  <div v-if="serviceTab == 'chat'">
                    <div class="top-div">
                      <chat></chat>
                      <h4>chat</h4>
                    </div>
                    <p
                      class="p-info text-left"
                    >All modules are integrated with connect/ contact feature. This allows Calling, Video Calling, Conference call, Intra App Messaging and Intra Centre Chat. The Chat module can synchronise your phone contacts for the above features. System groups allow grouping of people/ specialists.</p>
                  </div>
                  <!-- end chat -->
                  <!-- seller -->
                  <div v-if="serviceTab == 'seller'">
                    <div class="top-div">
                      <seller></seller>
                      <h4>Vendor</h4>
                    </div>
                    <p
                      class="p-info text-left"
                    >Vendors can reach out to target clients to sell their products online. Products can be listed as per Company, Products, Costings and other parameters. Online Payment and Bulk Selling is possible. Both free and Paid version to reach to target client is available. There is provision towards Tracking of Delivery both by Vendors and Clients.</p>
                  </div>
                  <!-- end seller-->
                  <!-- stockist -->
                  <div v-if="serviceTab == 'stockist'">
                    <div class="top-div">
                      <stockist></stockist>
                      <h4>pharmacy</h4>
                    </div>
                    <p class="p-info text-left">
                      five centuries, but also the leap into electronic
                      typesetting, remaining essentially unchanged. It was
                      popularised in the 1960s with the release of Letraset
                      sheets containing Lorem Ipsum passages, and more recently
                      with desktop publishing software like Aldus PageMaker
                      including versions of Lorem Ipsum.software like Aldus
                      PageMaker including versions of Lorem Ipsum.
                    </p>
                  </div>
                  <!-- end stockist -->
                  <!-- insurance -->
                  <div v-if="serviceTab == 'insurance'">
                    <div class="top-div">
                      <insurance></insurance>
                      <h4>Insurance</h4>
                    </div>
                    <p
                      class="p-info text-left"
                    >The insurance sector gets information in real time about their insured when they avail Admission as well as Out patient Services. Companies can check the status of admission in real time. Its unique feature allows digital transferring of files and bills with filtering of non-payables.</p>
                  </div>
                  <!-- end insurance -->
                  <!-- court -->
                  <div v-if="serviceTab == 'court'">
                    <div class="top-div">
                      <court></court>
                      <h4>court</h4>
                    </div>
                    <p
                      class="p-info text-left"
                    >The Government can see in real time the demography of disease prevalence and efficacy of various Government programs towards its redressal. Through the system, the Government can plan various strategies and evaluate its efficacy. They can reach out to various health stake holders and citizens to notify them or propagate its various schemes.</p>
                  </div>
                  <!-- end court -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- end services -->
    <!-- coming soon -->
    <section>
      <div class="comong-soon-main-top">
        <div class="con">
          <div class="div-left w-50v">
            <h1 class="h1">Coming Soon</h1>
            <p
              class="p-info"
            >Every version will surprise you with its new features. So keep abreast as ReLife-DocSmart unfolds itself over the next 6 to 8 months. New versions will be added and present one upgraded with time.</p>
            <!-- <p
              class="p-info"
            >magni aspernatur fugiat quo voluptatum illum necessitatibus vel rem nemo, odit iure magnam</p>-->
            <div class="notifyMe">
              <div>
                <input type="text" class="form-control" placeholder="Enter Your Email" />
              </div>
              <button class="btn btn-grn-dolid">
                Notify me
                <span>&#10230;</span>
              </button>
            </div>
          </div>
          <div class="div-right w-50v">
            <div class="product-top">
              <div class="div-left-prod">
                <div class="div1">
                  <div class="inner-div">
                    <div class="svg-div">
                      <packageSVG />
                    </div>
                    <p>Products</p>
                    <p>ReLife-DocSmart will be a one stop destination for all your Medical Product needs, be it small or major equipment’s.</p>
                  </div>
                  <div class="back-div">
                    <packageSVG />
                  </div>
                </div>
                <div class="div2">
                  <div class="inner-div">
                    <div class="svg-div">
                      <party />
                    </div>
                    <p>Events</p>
                    <p>Medical Events and Camps can be planned and propagated among target members. Members can search for upcoming events of their interest.</p>
                  </div>
                  <div class="back-div">
                    <party />
                  </div>
                </div>
              </div>
              <div class="div-right-prod">
                <div class="div3">
                  <div class="inner-div">
                    <div class="svg-div">
                      <interview />
                    </div>
                    <p>News</p>
                    <p>Medical News, Blogs, Pages will allow interaction in the app among its various members.</p>
                  </div>
                  <div class="back-div">
                    <interview />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- end coming soon -->
    <!-- contact -->
    <section>
      <div class="home-contact position-relative text-center">
        <div class="back-img">
          <img src="/img/cnt_bkg.png" alt="contact" class="hidden-xs" />
          <h1 class="h1">Contact Us</h1>
          <h3 class="mbtx">You may reach out to us for all your needs:</h3>
          <div class="py-40">
            <div class="contact-div">
              <div>
                <customerService></customerService>
              </div>
              <div class="tc">
                <h3 class="my-3">
                  <b>Customer Care</b>
                </h3>
                <h4>(+91) 7700048012</h4>
              </div>
            </div>
            <div class="contact-div">
              <div>
                <email></email>
              </div>
              <div class="tc">
                <h3 class="my-3">
                  <b>EMail</b>
                </h3>
                <h4>support@relifems.com</h4>
              </div>
            </div>
            <div class="contact-div">
              <div>
                <whatsappline />
              </div>
              <div class="tc">
                <h3 class="my-3">
                  <b>Whatsapp</b>
                </h3>
                <h4>(+91) 7208824901</h4>
              </div>
            </div>
          </div>
          <div style="padding: clamp(13px,.9vw,17px) clamp(50px,4.2vw,120px);">
            <!-- <button class="btn contact-btn">REQUEST A CALL BACK</button> -->
          </div>
        </div>
      </div>
    </section>
    <div @click="toTop()" id="scrollTo" :class="showTopButton">
      <rightarrow></rightarrow>
    </div>
    <!-- <button onclick="topFunction()" id="myBtn" title="Go to top">Top3</button> -->
    <!-- end contact -->
    <div>
      <bottomNavigation />
    </div>
    <v-dialog v-model="serviceModal" width="clamp(650px,40vw,900px)" persistent>
      <service-form-modal @close="serviceModal = false"></service-form-modal>
    </v-dialog>
  </div>
</template>
<style lang="scss">
.home-main-wrapper .comong-soon-main-top .con .div-left .notifyMe .btn-grn-dolid {
    background-color: #01a09b;
    color: #fff;
    height: 38px;
    margin-left: 1vw;
    padding: 0 1.5vw;
    font-size: 17px;
    font-weight: 700;
    min-width: 11rem;
}

#myBtn {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  font-size: 18px;
  border: none;
  outline: none;
  background-color: red;
  color: white;
  cursor: pointer;
  padding: 15px;
  border-radius: 4px;
}

#myBtn:hover {
  background-color: #555;
}


</style>
<script>
import ServiceFormModal from "@/components/masters/ServiceFormModal";
import topNavigation from "@/components/masters/topNavigation";
import bottomNavigation from "@/components/masters/bottomNavigation";
import { pickSvg } from "@/svg";
export default {
  data() {
    return {
      serviceTab: "doctor",
      serviceModal: false,
      showTopButton: "",
    };
  },

  components: {
    topNavigation,
    bottomNavigation,
    ServiceFormModal,
    ...pickSvg(
      "doctor",
      "ambulance",
      "email",
      "rightarrow",

    )
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.showTopButton = window.scrollY == 0 ? "scroll-top" : "";
    },
    toTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
    },
    showCurrent() {
      setTimeout(function() {
        var elmnt = document.getElementById("docsmartServices");
        elmnt.scrollIntoView({ block: "center", behavior: "smooth" });
      }, 200);
    }
  }
};

</script>