import doctor from "./doctor";
import ambulance from "./ambulance";
import hospital from "./hospital";
import medicalRecord from "./medicalRecord";
import prescription from "./prescription";
import blood from "./blood";
import customerService from "./customerService";
import service from "./service";
import patientSVG from "./patient";
import chat from "./chat";
import seller from "./seller";
import stockist from "./stockist";
import insurance from "./insurance";
import court from "./court";
import packageSVG from "./package";
import interview from "./interview";
import party from "./party";
import envelope from "./envelope";
import whatsappline from "./whatsappline";
import likebkg from "./likebkg";
import like from "./like";
import facebook from "./facebook";
import instagram from "./instagram";
import twitterLogo from "./twitterLogo";
import linkedin from "./linkedin";
import bloggerLogo from "./bloggerLogo";
import android from "./android";
import apple from "./apple";
import email from "./email";
import rightarrow from "./rightarrow";
import leftQuote from "./leftQuote";
import backward from "./backward";
let svgRegistries = {
  doctor: doctor,
  android: android,
  apple: apple,
  ambulance: ambulance,
  hospital: hospital,
  medicalRecord: medicalRecord,
  prescription: prescription,
  blood: blood,
  customerService: customerService,
  service: service,
  patientSVG: patientSVG,
  chat: chat,
  seller: seller,
  stockist: stockist,
  insurance: insurance,
  court: court,
  packageSVG: packageSVG,
  interview: interview,
  party: party,
  envelope: envelope,
  whatsappline: whatsappline,
  likebkg: likebkg,
  like: like,
  facebook: facebook,
  instagram: instagram,
  twitterLogo: twitterLogo,
  linkedin: linkedin,
  bloggerLogo: bloggerLogo,
  email: email,
  rightarrow: rightarrow,
  leftQuote: leftQuote,
  backward: backward,
};

//eslint-disable-next-line
let pickSvg = (...args) => {
  let wrap = ({ ...args }) => ({ ...args });
  return wrap(svgRegistries);
};

export { pickSvg, svgRegistries };
