<template>
  <footer>
    <div class="footer-main">
      <div class="text-center">
        <h1 class="mt-10">REACH OUT TO US: DOWNLOAD TODAY!</h1>
        <!-- <h3>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry.
        </h3>-->
      </div>
      <div class="footer-sec-1">
        <div class="con-2 w-50v pt-220 position-relative">
          <div class="like-back hidden-xs">
            <likebkg></likebkg>
          </div>
          <div class="like12">
            <div class="like">
              <like></like>
            </div>

            <h1 class="h1">
              User friendly Interface,
              <br class="hidden-xs" />Very easy to use
            </h1>
          </div>
          <p class="p-info my-0 mpb-0">
            You may download the app via the GOOGLE PLAY Store or APPLE Store.
            <br />You may download the desktop sortcut link. <br />Please follow
            us
            <a
              href="https://www.facebook.com/DocSmartHealthcareSystem/"
              target="_blank"
              aria-label="Facebook"
              rel="noopener"
              >@Facebook</a
            >,
            <a
              href="https://twitter.com/DocSmartapp"
              target="_blank"
              aria-label="Twitter"
              rel="noopener"
              >@Twitter</a
            >,
            <a
              href="https://instagram.com/docsmart.hs?igshid=11s3fgr4bfz97"
              target="_blank"
              aria-label="Instagram"
              rel="noopener"
              >@Instagram</a
            >
            by clicking the link below.
          </p>
          <!-- <div class="visible-xs">
            <img src="/img/footer_m-2.png" alt class="mob-foo-img1" />
            <img src="/img/footer_m-1.png" alt class="mob-foo-img" />
          </div>-->
          <div class="store-btn">
            <a
              class="app-btn"
              href="https://apps.apple.com/us/app/docsmart/id1563783984"
              target="_blank"
            >
              <div>
                <apple></apple>
              </div>
              <div>
                <p class="py-0">Download on the</p>
                <h4>App Store</h4>
              </div>
            </a>
            <a
              class="app-btn"
              href="https://play.google.com/store/apps/details?id=in.docsmart.app"
              target="_blank"
            >
              <div>
                <android></android>
              </div>
              <div>
                <p class="py-0">Download on the</p>
                <h4>Play Store</h4>
              </div>
            </a>
          </div>
        </div>
        <div class="visible-xs">
          <img
            src="/img/footer_m-2.png"
            alt="floating image"
            class="mob-foo-img1"
          />
          <img
            src="/img/footer_m-1.png"
            alt="floating image"
            class="mob-foo-img"
          />
        </div>
        <div class="w-50 hidden-xs">
          <div class="footer-sec-2">
            <div>
              <img src="/img/footer_m-1.png" alt class="floating" />
            </div>
            <div>
              <img src="/img/footer_m-2.png" alt class="floating2" />
            </div>
          </div>
        </div>
      </div>
      <div class="mt-3vw">
        <img
          src="/img/footer_bkg.png"
          alt
          class="footer-back position-relative hidden-xs"
        />
        <div class="footer-nav">
          <div>
            <ul class="foo-ul lis">
              <li>
                <a href="https://www.docsmart.in/faq" target="_blank">FAQ</a>
              </li>
              <li>
                <router-link :to="{ name: 'About' }" target="_blank"
                  >About</router-link
                >
              </li>
              <!-- <li>Whats New</li> -->
              <li>
                <a
                  href="https://www.docsmart.in/terms-conditions"
                  target="_blank"
                  >Terms and Conditions</a
                >
                <!-- <router-link :to="{name:'TermsConditions'}" target="_blank">Terms and Conditions</router-link> -->
              </li>
              <li>
                <a href="https://www.docsmart.in/privacy-policy" target="_blank"
                  >Privacy Policy</a
                >
                <!-- <router-link :to="{ name: 'PrivacyPolicy'}" target="_blank">Privacy Policy</router-link> -->
              </li>
            </ul>
          </div>
          <div>
            <ul class="foo-ul md-flex">
              <li>
                <a
                  href="https://www.facebook.com/DocSmartHealthcareSystem"
                  target="_blank"
                  ><facebook></facebook
                ></a>
              </li>
              <li>
                <a href="https://www.instagram.com/docsmart.in/" target="_blank"
                  ><instagram></instagram
                ></a>
              </li>
              <li>
                <a href="https://twitter.com/DocSmartapp" target="_blank"
                  ><twitterLogo></twitterLogo
                ></a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/relifems/"
                  target="_blank"
                  ><linkedin></linkedin
                ></a>
              </li>
              <!-- <li>
                <bloggerLogo></bloggerLogo>
              </li> -->
            </ul>
          </div>
        </div>
        <div class="footer-end">
          <p>All rights reserved. &copy; 2021 RELIFEMS</p>
        </div>
      </div>
    </div>
  </footer>
</template>
<style>
</style>
<script>
import { pickSvg } from "@/svg";
export default {
  components: {
    ...pickSvg(
      "likebkg",
      "like",
      "facebook",
      "apple",
      "instagram",
      "twitterLogo",
      "linkedin",
      "bloggerLogo"
    ),
  },
};
</script>
<style scoped>
.mt-3vw {
  margin-top: 3rem;
}
.like-back svg {
  top: -5vw !important;
  transform: scaleZ(-1) !important;
}
</style>