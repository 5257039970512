<template>
  <!-- nav -->
  <!-- <header class="header-main">
    <nav class="navbar navbar-expand-lg navbar-light bg-white">
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarTogglerDemo01"
        aria-controls="navbarTogglerDemo01"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse position-relative" id="navbarTogglerDemo01">
        <a class="navbar-brand" href="#">
          <router-link :to="{ name: 'Home'}">
            <img src="/img/docsmart_logo.png" alt="logo" />
          </router-link>
        </a>
        <ul class="navbar-nav my-0 pl-0">
          <li class="nav-item active mr-4">
            <router-link
              class="text-capitalize nav-link"
              active-class="active"
              :to="{ name: 'About'}"
            >About Us</router-link>
          </li>
          <li class="nav-item mr-4">
            <router-link
              class="text-capitalize nav-link"
              :to="{ name: 'Services'}"
            >Services</router-link>
          </li>
          <li class="nav-item">
            <router-link
              class="text-capitalize nav-link"
              :to="{ name: 'Contact'}"
            >Contact Us</router-link>
          </li>
        </ul>
        <ul class="navbar-nav my-0 Docsmart">
          <li class="nav-item">
            <a class="nav-link" href="https://docsmart.in/" target="_blank">Signup for Docsmart</a>
          </li>
        </ul>
      </div>
    </nav>
  </header> -->

  <div class="nav">
  <input type="checkbox" id="nav-check">
  <div class="nav-header">
    <div class="nav-title">
      <a class="navbar-brand" href="#">
          <router-link :to="{ name: 'Home'}">
            <img src="/img/docsmart_logo.png" alt="logo" />
          </router-link>
        </a>
    </div>
  </div>
  <div class="nav-btn">
    <label for="nav-check">
      <span></span>
      <span></span>
      <span></span>
      <h2 class="d-none">X</h2>
    </label>
  </div>
  
  <div class="nav-links">
    <router-link
      class="text-capitalize nav-link"
      active-class="active"
      :to="{ name: 'About'}"
    >About Us</router-link>
    <router-link
      class="text-capitalize nav-link"
      :to="{ name: 'Services'}"
    >Services</router-link>
    <router-link
      class="text-capitalize nav-link"
      :to="{ name: 'Contact'}"
    >Contact Us</router-link>
    <a class="nav-link sfd" href="https://docsmart.in/" target="_blank">Signup for Docsmart</a>
  </div>

  <div class="nav-links sec">
    <a class="nav-link" href="https://docsmart.in/" target="_blank">Signup for Docsmart</a>
  </div>
</div>
  <!-- end nav -->
</template>
<style>

</style>
<script>
export default {
  components: {}
};
</script>

