<template>
  <v-app>
    <template>
      <router-view></router-view>
    </template>
  </v-app>
</template>

<script>
export default {
  name: "App",

  components: {},

  data: () => ({
    //
  }),
  created() {
    console.log("created this.$route", this.$route);
  },
  beforeUpdate() {
    console.log("this.$route", this.$route);
  }
};
</script>
